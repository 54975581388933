import React from "react";

import { ChooseDate } from "@legup/legup-react-components";

import { Theme, makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";

import strings from "../../infra/constants/strings";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}));

type SpotDatesProps = {
  addDate?: Date;
  preferredDate: Date;
  minPreferredDate?: Date;
  readOnly?: boolean;
  canChangeAddDate?: boolean;
  onChange: (date: Date, type: "add" | "preferred") => void;
};

const SpotDates = (props: SpotDatesProps) => {
  const classes = useStyles(props);
  const [addDate, setAddDate] = React.useState(props.addDate ? moment(props.addDate) : undefined);
  const [preferredDate, setPreferredDate] = React.useState(props.preferredDate);

  React.useEffect(() => {
    setPreferredDate(props.preferredDate);
  }, [props.preferredDate]);

  const onCustomAddDateChange = (e: any) => {
    const date = e.target.checked ? moment() : undefined;
    setAddDate(date);
    props.onChange(date ? date.toDate() : undefined, "add");
  };

  const onAddDateChange = (date: moment.Moment) => {
    setAddDate(date);
    props.onChange(date.toDate(), "add");
  };

  const onPreferredDateChange = (date: Date) => {
    setPreferredDate(date);
    props.onChange(date, "preferred");
  };

  return (
    <Grid container>
      {props.canChangeAddDate ? (
        <>
          <Grid item xs={6}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={!!addDate}
                  onChange={onCustomAddDateChange}
                  disabled={props.readOnly}
                  name="custom-add"
                  color="primary"
                />
              )}
              label={strings.addToWaitlist.customAddDate}
            />
          </Grid>
          <Grid item xs={6}>
            {addDate ? (
              <DatePicker
                inputVariant="outlined"
                id="add_date"
                name="add_date"
                format="MM/DD/YYYY"
                size="small"
                label={strings.addToWaitlist.addDate}
                allowKeyboardControl
                autoOk
                views={["month", "date", "year"]}
                disabled={props.readOnly}
                value={addDate}
                onChange={onAddDateChange}
              />
            )
              : null}
          </Grid>
        </>
      ) : null}
      <Grid item xs={12}>
        <ChooseDate
          date={preferredDate}
          monthOnly
          readOnly={props.readOnly}
          minDate={props.minPreferredDate}
          title={strings.addToWaitlist.preferredDate}
          yearMin={0}
          yearMax={4}
          onChange={onPreferredDateChange}
          titleWidth={200}
        />
      </Grid>
    </Grid>
  );
};

export default SpotDates;
