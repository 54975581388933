import React from "react";
import moment from "moment";

import { LimitTextField } from "@legup/legup-react-components";

import { DatePicker } from "@material-ui/pickers";
import { Theme, makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import { formatDate, renderStringWithHTML } from "../../infra/utils";
import strings from "../../infra/constants/strings";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  question: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
  hint: {
    fontSize: 14,
    fontStyle: "italic",
    marginBottom: theme.spacing(1),
  },
}));

type AnswerQuestionProps = {
  questions: any,
  initialAnswers?: any,
  referral?: string,
  readOnly?: boolean,
  showTitle?: boolean,
  form: "waitlist" | "deposit",
  onChange: ((answers: any, referral: string | undefined, priority: number) => void),
  error?: boolean,
};

const AnswerQuestions = (props: AnswerQuestionProps) => {
  const setupAnswers = (() => {
    // Set up answers to match questions
    const ans = [];
    const questions = props.questions || [];
    questions.forEach(q => {
      // If we have initial answers and one that matches this ID, use that
      let answer = "";
      if (props.initialAnswers) {
        const initAns = props.initialAnswers.find(a => a.id === q.id);
        if (initAns) {
          answer = initAns.answer;
        }
      }
      ans.push({ id: q.id, answer });
    });

    return ans;
  });

  const classes = useStyles(props);
  const [answers, setAnswers] = React.useState(setupAnswers());
  const [referral, setReferral] = React.useState(props.referral);
  const [priority, setPriority] = React.useState(10);
  const [htmlAnswers, setHtmlAnswers] = React.useState<{[s: string]: string}>({});

  React.useEffect(() => {
    setAnswers(setupAnswers());

    // Load any HTML that we need to
    (props.questions || []).forEach(q => {
      if (q.type === "html") {
        loadHTML(q.id, q.question);
      }
    });
  }, [props.questions]);

  React.useEffect(() => {
    setReferral(props.referral);
  }, [props.referral]);

  const loadHTML = (id: string, url: string) => {
    fetch(url).then(response => response.text()).then(html => {
      // This is the HTML from our response as a text string
      const newHtml = { ...htmlAnswers };
      newHtml[id] = html;
      setHtmlAnswers(newHtml);
    }).catch(err => {
      // There was an error
      console.log("Something went wrong.", err);
    });
  };

  const onChangeAnswer = (e: any) => {
    const idx = e.target.name.split(".")[1];
    const ans = Object.assign([], answers);
    ans[idx].answer = e.target.value;
    setAnswers(ans);
    props.onChange(ans, referral, priority);
  };

  const onChangeReferral = (e: any) => {
    setReferral(e.target.value);
    props.onChange(answers, e.target.value, priority);
  };

  const onSelectDate = (idx: number, date: moment.Moment) => {
    // Set the answer to the text represetation of this date
    const ans = Object.assign([], answers);
    ans[idx].answer = formatDate(date.toDate());
    setAnswers(ans);
    props.onChange(ans, referral, priority);
  };

  const showReferral = (): boolean => {
    let show = false;
    props.questions.forEach(q => {
      if (q.type === "referral") {
        // See if they answered yes
        const answer = answers.find(a => a.id === q.id);
        if (answer && (answer.answer.toLowerCase() === "yes")) {
          show = true;
        }
      }
    });

    return show;
  };

  const onSelectChoice = ((e: any, idx: number) => {
    const ans = Object.assign([], answers);
    ans[idx].answer = e.target.value;

    // Check what the priority should be
    let pri = 10;

    ans.forEach(a => {
      const q = props.questions.find(question => (question.id === a.id));
      if (q && q.choices) {
        const c = q.choices.find(x => (x.text === a.answer));
        if (c) {
          pri = Math.max(pri, c.priority ? c.priority : 0);
        }
      }
    });

    setPriority(pri);
    setAnswers(ans);
    props.onChange(ans, referral, pri);
  });

  if (props.questions && answers && (props.questions.length === answers.length)) {
    return (
      <>
        {props.showTitle ? (
          <>
            <Divider variant="fullWidth" className={classes.divider} />
            <Typography variant="h2">{strings.answerQuestion.sectionQuestions}</Typography>
          </>
        ) : null}
        {props.error
          ? (
            <Typography color="error" variant="subtitle2">
              {strings.answerQuestion.missingAnswers}
            </Typography>
          ) : null}
        {props.questions.map((q, idx) => {
          if (q.type === "html") {
            if (htmlAnswers[q.id]) {
              return (
                <div className={classes.formControl}>
                  {renderStringWithHTML(htmlAnswers[q.id])}
                </div>
              );
            }
 
            return null;
            
          }

          const required = (props.form === "waitlist") ? q.required : q.deposit_required;

          return (
            <div>
              <FormControl fullWidth component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">
                  <Typography variant="body1" className={classes.question}>{`${q.question}${required ? " (*)" : ""}`}</Typography>
                </FormLabel>
                {q.hint ? <Typography variant="subtitle1" className={classes.hint}>{q.hint}</Typography> : null}
                {q.choices ? ((q.choices.length <= 2) ? (
                  <RadioGroup
                    aria-label="choose answer"
                    name={`choice-group.${idx}`}
                    value={answers[idx].answer}
                    onChange={e => onSelectChoice(e, idx)}
                  >
                    {q.choices.map((c, j) => (
                      <FormControlLabel
                        key={`choice.${idx}.${j}`}
                        value={c.text}
                        id={`choice.${idx}.${j}`}
                        control={<Radio key={c.text} disabled={props.readOnly} />}
                        label={c.text}
                      />
                    ))}
                  </RadioGroup>
                ) : (
                  <Select
                    variant="outlined"
                    key={`select-${idx}`}
                    value={answers[idx].answer}
                    onChange={e => onSelectChoice(e, idx)}
                  >
                    {q.choices.map((c, j) => (
                      <MenuItem key={`select-choice-${idx}.${j}`} value={c.text}>{c.text}</MenuItem>
                    ))}
                  </Select>
                ))
                  : (q.type === "date" ? (
                    <DatePicker
                      inputVariant="outlined"
                      id="date"
                      name="date"
                      format="MM/DD/YYYY"
                      label={strings.answer}
                      allowKeyboardControl
                      autoOk
                      views={["month", "date", "year"]}
                      value={moment(answers[idx].answer.length ? answers[idx].answer : undefined)}
                      onChange={instant => onSelectDate(idx, instant)}
                    />
                  )
                    : (
                      <TextField
                        id={`question.${idx}`}
                        name={`question.${idx}`}
                        key={`question.${idx}`}
                        label={strings.answer}
                        variant="outlined"
                        fullWidth
                        disabled={props.readOnly}
                        value={answers[idx].answer}
                        onChange={onChangeAnswer}
                      />
                    ))}
              </FormControl>
            </div>
          );
        })}
        {showReferral() && (
          <FormControl fullWidth component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">
              <Typography variant="h3" className={classes.question}>{strings.answerQuestion.referralLabel}</Typography>
            </FormLabel>
            <LimitTextField
              id="referral"
              name="referral"
              key="referral"
              variant="outlined"
              fullWidth
              disabled={props.readOnly}
              value={referral}
              limit={500}
              onChange={onChangeReferral}
            />
          </FormControl>
        )}
      </>
    );
  }
 
  return null;
  
};

AnswerQuestions.defaultProps = {
  showTitle: true,
};

export default AnswerQuestions;
