import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { CenterQuestion } from "@legup/legup-model";

import { Theme, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import RootRef from "@material-ui/core/RootRef";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashXmark } from "@fortawesome/pro-light-svg-icons";

import { getQuestionPrioirtyRank, getQuestionType } from "../../infra/utils";
import strings from "../../infra/constants/strings";

import EditQuestion from "./EditQuestion";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  header: {
    fontSize: "16px",
    fontWeight: "bold",
    cursor: "default",
  },
  item: {
    fontSize: "14px",
  },
  questionCheckbox: {
    paddingTop: theme.spacing(2),
  },
  addicon: {
    top: theme.spacing(1),
  },
  deleteButton: {
    left: theme.spacing(-2),
    "& path": {
      fill: theme.palette.primary.main,
    },
  },
}));

type CenterQuestionsProps = {
  questions: CenterQuestion[],
  showProviderOption: boolean,
  legupList?: boolean,
  form?: "waitlist" | "deposit" | "both",
  onChange: ((q: CenterQuestion[]) => void),
};

const CenterQuestions = (props: CenterQuestionsProps) => {
  const classes = useStyles(props);
  const [addKey, setAddKey] = React.useState(1);
  const [questions, setQuestions] = React.useState(props.questions);
  const [showEditQuestion, setShowEditQuestion] = React.useState(false);
  const [addingQuestion, setAddingQuestion] = React.useState(false);
  const [editQuestion, setEditQuestion] = React.useState(undefined);

  React.useEffect(() => {
    setQuestions(props.questions);
  }, [props.questions]);

  const copyQuestions = () => {
    let newQuestions;
    if (questions) {
      newQuestions = [];
      questions.forEach((q: CenterQuestion) => {
        newQuestions.push(q.copy());
      });
    }

    return newQuestions;
  };

  const closeEditQuestion = () => {
    setShowEditQuestion(false);
    setAddingQuestion(false);
    setEditQuestion(undefined);
  };

  const saveEditQuestion = (question: CenterQuestion) => {
    let newQuestions;
    if (addingQuestion) {
      newQuestions = questions ? copyQuestions() : [];
      newQuestions.push(question);
    }
    else {
      // Replace the old question with this new one
      newQuestions = [];
      questions.forEach((qu, idx) => {
        if (idx === editQuestion) {
          newQuestions.push(question);
        }
        else {
          newQuestions.push(qu);
        }
      });
    }

    setQuestions(newQuestions);
    setShowEditQuestion(false);
    setAddingQuestion(false);
    setEditQuestion(undefined);
    props.onChange(newQuestions);
  };

  const onAddQuestion = () => {
    setAddKey(addKey + 1);
    setAddingQuestion(true);
    setShowEditQuestion(true);
  };

  const onRemoveQuestion = (e: any, idx: number) => {
    e.stopPropagation();
    const newQuestions = Object.assign([], questions);
    newQuestions.splice(idx, 1);
    setQuestions(newQuestions);
    props.onChange(newQuestions);
  };

  const onClickQuestion = (idx: number) => {
    setEditQuestion(idx);
    setShowEditQuestion(true);
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // styles we need to apply on draggables
    ...draggableStyle,
    ...(isDragging && {
      background: "rgb(235,235,235)",
    }),
  });

  const onDragEnd = (result: any) => {
    if (result.destination && result.draggableId) {
      // See if the index changed and if so reposition this question in the list
      const id = result.draggableId.split(".")[1];
      const idx = questions.findIndex(q => q.id === id);
      if (result.destination.index !== idx) {
        // Center-specific questions must go below provider-level questions
        // So let's update the index if we are trying to move "between groups"
        const q = questions[idx];
        let destIndex = result.destination.index;
        if (q) {
          if (q.getProviderQuestion()) {
            // Don't allow it to be dragged lower than a center question
            while ((destIndex >= 0) && !questions[destIndex].getProviderQuestion()) {
              destIndex--;
            }
          }
          else {
            // Don't allow it to be dragged higher than a provider question
            while ((destIndex < questions.length) && questions[destIndex].getProviderQuestion()) {
              destIndex++;
            }
          }
        }
        // OK, now we can move the question
        const newQuestions = Object.assign([], questions);
        const [removed] = newQuestions.splice(idx, 1);
        newQuestions.splice(destIndex, 0, removed);
        setQuestions(newQuestions);
        props.onChange(newQuestions);
      }
    }
  };

  const rankedPriorities = getQuestionPrioirtyRank(questions);

  return (
    <>
      {questions && (
        <>
          <Typography variant="subtitle2">
            {props.legupList ? strings.centerQuestions.editCaptionLegup : strings.centerQuestions.editCaption}
          </Typography>
          <DragDropContext onDragEnd={onDragEnd}>
            <Table stickyHeader aria-label="table" size="small">
              <TableHead>
                <TableRow>
                  {props.form === "both" ? <TableCell className={classes.header}>{strings.centerQuestions.colForm}</TableCell> : null}
                  <TableCell className={classes.header}>{strings.centerQuestions.colPriority}</TableCell>
                  <TableCell className={classes.header}>{strings.centerQuestions.colQuestion}</TableCell>
                  <TableCell className={classes.header}>{strings.centerQuestions.colRequired}</TableCell>
                  <TableCell className={classes.header}>{strings.centerQuestions.colQuestionType}</TableCell>
                  <TableCell className={classes.header}>{strings.centerQuestions.colDelete}</TableCell>
                </TableRow>
              </TableHead>
              <Droppable droppableId="table">
                {droppableProvided => (
                  <RootRef rootRef={droppableProvided.innerRef}>
                    <TableBody>
                      {questions.map((qu, idx) => {
                        let pri = 10;
                        let priText;
                        (qu.getChoices() || []).forEach(c => {
                          if (c.priority > pri) {
                            pri = c.priority;
                          }
                        });
                        if (pri > 10) {
                          priText = (1 + rankedPriorities.indexOf(pri))?.toString();
                        }
                        else {
                          priText = strings.centerQuestions.defaultPriority;
                        }

                        if ((props.form === "both") || (qu.getForm() === "both") || (props.form === qu.getForm())) {
                          const formText = (qu.getForm() === "waitlist") ? strings.centerQuestions.formWaitlist
                            : ((qu.getForm() === "deposit") ? strings.centerQuestions.formDeposit
                              : ((qu.getForm() === "both") ? strings.centerQuestions.formBoth : ""));
                          const required = (props.form === "both") ? (qu.required || qu.deposit_required)
                            : ((props.form === "waitlist") ? qu.required : qu.deposit_required);

                          return (
                            <Draggable
                              draggableId={`row.${qu.id}`}
                              index={idx}
                              key={`drag.${qu.id}`}
                            >
                              {(provided, snapshot) => (
                                <RootRef rootRef={provided.innerRef}>
                                  <TableRow
                                    id={`row.${qu.id}`}
                                    isDragging={snapshot.isDragging}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    onClick={() => onClickQuestion(idx)}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    {props.form === "both" ? <TableCell className={classes.item} key={`form.${idx}`}>{formText}</TableCell> : null}
                                    <TableCell className={classes.item} key={`priority.${idx}`}>{priText}</TableCell>
                                    <TableCell className={classes.item}>{qu.getType() === "html" ? "HTML" : qu.question}</TableCell>
                                    <TableCell className={classes.item}>{required ? "Yes" : "No"}</TableCell>
                                    <TableCell className={classes.item}>{strings.editQuestion.types[getQuestionType(qu)]}</TableCell>
                                    <TableCell>
                                      <IconButton className={classes.deleteButton} onClick={e => onRemoveQuestion(e, idx)}>
                                        <FontAwesomeIcon 
                                          icon={faTrashXmark} 
                                          className={classes.deleteButton}
                                        />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                </RootRef>
                              )}
                            </Draggable>
                          );
                        }
 
                        return null;
                
                      })}
                      {droppableProvided.placeholder}
                    </TableBody>
                  </RootRef>
                )}
              </Droppable>
            </Table>
          </DragDropContext>
        </>
      )}
      <EditQuestion
        key={addingQuestion ? `add.${addKey}` :
          (((editQuestion !== undefined) ? `eq.${questions[editQuestion].getQuestion}` : "0"))}
        question={(editQuestion !== undefined) ? questions[editQuestion] : undefined}
        form={props.form}
        adding={addingQuestion}
        showProviderOption={props.showProviderOption}
        allQuestions={questions}
        open={showEditQuestion}
        onClose={closeEditQuestion}
        onOK={saveEditQuestion}
      />
      <Button
        className={classes.button}
        variant="contained"
        size="small"
        color="secondary"
        fullWidth
        onClick={onAddQuestion}
      >
        {strings.centerQuestions.addQuestion}
      </Button>
    </>
  );
};

CenterQuestions.defaultProps = {
  form: "both",
};

export default CenterQuestions;
