import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { kinsideTokens } from "@legup/legup-react-components";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

const useStyles = props => makeStyles(() => ({
  helperAlert: {
    borderRadius: "8px",
    height: "fit-content",
    backgroundColor: props.color,
  },
  helperAlertText: {
    color: props.textColor,
    fontSize: props.textSize,
    fontWeight: 400,
    paddingBlock: 0,
    alignSelf: "center",
  },
  icon: {
    ...props.iconStyle,
  },
}));

type HelperProps = {
  text: string,
  icon?: IconDefinition,
  color?: string,
  textColor?: string,
  textSize?: string
  classes?: string
  iconStyle?: {[s: string]: string},
};

const Helper = (props: HelperProps) => {  
  const classes = useStyles(props)();

  return (
    <Alert 
      className={`${classes.helperAlert} ${classes.helperAlertText} mb-2 ${props.classes}`}
      icon={props.icon ? (
        <FontAwesomeIcon
          className={`${classes.helperAlertText} ${props.iconStyle ? classes.icon : undefined}`}
          icon={props.icon}
        />
      ) : <></>}
    >
      {props.text}
    </Alert>
  );
};

export default Helper;

Helper.defaultProps = {
  color: kinsideTokens["--gold-5"],
  textColor: kinsideTokens["--gray-80"],
  textSize: "13px",
};
