import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ChildForm } from "@legup/legup-react-components";
import { Child, Parent, Source } from "@legup/legup-model";

import { addChild, updateChild } from "../../actions/childActions";

type EditChildProps = {
  onClose?: () => void;
  addingChild?: boolean;
  parents: Parent[];
  child: Child;
  useSameAddress?: boolean;
  noSaveButton?: boolean;
  noAddress?: boolean;
  noGender?: boolean;
  readOnly?: boolean;
  disallowOther?: boolean;
  conciergeFamily?: boolean;
  onChange?: (child: Child, field: string) => void;
  validation?: boolean;
};

const EditChild = (props: EditChildProps) => {
  const addSuccess = useSelector((state: any) => state.childReducer.addSuccess);
  const updateSuccess = useSelector((state: any) => state.childReducer.updateSuccess);
  const dispatch = useDispatch();

  useEffect(() => {
    if (addSuccess && props.onClose) {
      props.onClose();
    }
  }, [addSuccess]);

  useEffect(() => {
    if (updateSuccess && props.onClose) {
      props.onClose();
    }
  }, [updateSuccess]);

  const saveChild = (child: Child) => {
    if (props.addingChild) {
      // Note the source will always be direct if they are being added in the admin tool
      // Unless this family (other children) are Concierge, in which case this will also
      // be consider a concierge child
      child.setSource(props.conciergeFamily ? Source.CONCIERGE : Source.DIRECT);
      dispatch(addChild(props.parents[0].getId(), child));
    }
    else {
      dispatch(updateChild(child));
    }
  };

  if (!props.child) {
    return null;
  }

  return (
    <>
      <ChildForm
        readOnly={props.readOnly}
        child={props.child}
        parents={props.parents}
        saveButton={!props.noSaveButton}
        disallowOther={props.disallowOther}
        noAddress={props.noAddress}
        noGender={props.noGender}
        onChange={props.onChange}
        onSave={saveChild}
        validation={props.validation}
      />
    </>
  );
};

export default EditChild;
