import React from "react";

import { Theme, makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme: Theme) => ({
  "image-xs": {
    "max-height": 48,
    "max-width": 60,
    height: "auto",
    width: "auto",
  },
  "image-sm": {
    "max-height": 80,
    "max-width": 100,
    height: "auto",
    width: "auto",
  },
  "image-md": {
    "max-height": 120,
    "max-width": 150,
    height: "auto",
    width: "auto",
  },
  image: {
    marginBlock: "5px",
  },
}));

type ProviderLogoProps = {
  logoUrl: string | undefined,
  size?: "xs" | "sm" | "md",
};

const ProviderLogo = (props: ProviderLogoProps) => {
  const classes = useStyles(props);

  if (!props.logoUrl) {
    return null;
  }

  if (props.size) {
    const classWithSize = classes[`image-${props.size}`];

    return <img src={props.logoUrl} className={`${classWithSize} ${classes.image}`} />;
  }

  return (
    <>
      <Hidden smDown>
        <img src={props.logoUrl} className={`${classes["image-md"]} ${classes.image}`} />
      </Hidden>
      <Hidden mdUp>
        <img src={props.logoUrl} className={`classes["image-sm"] ${classes.image}`} />
      </Hidden>
    </>
  );
};

export default ProviderLogo;
