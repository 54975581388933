import React from "react";
import { useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import TableRow from "@material-ui/core/TableRow";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Alert, ConfirmDialog, Loading } from "@legup/legup-react-components";

import { Event, trackEventLegacy } from "../../infra/tracking";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 24,
      marginRight: 24,
    },
    closeButton: {
      "& path": {
        fill: theme.palette.grey[500],
      },
    },
  }),
);

// Elements that should send an event onClick

type EventClickProps = {
  event?: Event,
  metadata?: string,
  onClick?: ((e: any) => void),
  [s: string]: any,
};

export const ClickableControl = (Control: any, props: EventClickProps) => {
  const groups = useSelector((state: any) => state.clientStateReducer.groups);
  const email = useSelector((state: any) => state.clientStateReducer.email);

  const handleClick = (e: any) => {
    trackEventLegacy(email, groups, props.event, props.metadata);
    props.onClick(e);
  };

  if (props.event) {
    return <Control {...props} onClick={props.onClick ? handleClick : undefined} />;
  }

  return <Control {...props} />;

};

export const EventButton = (props: EventClickProps) => ClickableControl(Button, props);

export const EventButtonBase = (props: EventClickProps) => ClickableControl(ButtonBase, props);

export const EventIconButton = (props: EventClickProps) => ClickableControl(IconButton, props);

export const EventLink = (props: EventClickProps) => ClickableControl(Link, props);

export const EventMenuItem = (props: EventClickProps) => ClickableControl(MenuItem, props);

export const EventTableRow = (props: EventClickProps) => ClickableControl(TableRow, props);

// Checkbox control

type EventCheckboxProps = {
  event?: Event,
  onChange?: ((e: any) => void),
  [s: string]: any,
};

export const EventCheckbox = (props: EventCheckboxProps) => {
  const groups = useSelector((state: any) => state.clientStateReducer.groups);
  const email = useSelector((state: any) => state.clientStateReducer.email);

  const handleChange = (e: any) => {
    trackEventLegacy(email, groups, props.event, e.target.checked ? "on" : "off");
    props.onChange(e);
  };

  if (props.event) {
    return <Checkbox {...props} onChange={props.onChange ? handleChange : undefined} />;
  }

  return <Checkbox {...props} />;
};

// Switch control

type EventSwitchProps = {
  event?: Event,
  onChange?: ((e: any) => void),
  [s: string]: any,
};

export const EventSwitch = (props: EventSwitchProps) => {
  const groups = useSelector((state: any) => state.clientStateReducer.groups);
  const email = useSelector((state: any) => state.clientStateReducer.email);

  const handleChange = (e: any) => {
    trackEventLegacy(email, groups, props.event, e.target.checked ? "on" : "off");
    props.onChange(e);
  };

  if (props.event) {
    return <Switch {...props} onChange={props.onChange ? handleChange : undefined} />;
  }

  return <Switch {...props} />;
};

// Tabs control

type EventTabsProps = {
  event?: Event,
  metadata?: string[],
  onChange?: ((e: any, idx: number) => void),
  value?: number,
  [s: string]: any,
};

export const EventTabs = (props: EventTabsProps) => {
  const groups = useSelector((state: any) => state.clientStateReducer.groups);
  const email = useSelector((state: any) => state.clientStateReducer.email);

  const handleChange = (e: any, idx: number) => {
    const metadata = props.metadata ? props.metadata[idx] : undefined;
    trackEventLegacy(email, groups, props.event, metadata);
    props.onChange(e, idx);
  };

  if (props.event) {
    return <Tabs {...props} value={props.value} onChange={props.onChange ? handleChange : undefined} />;
  }

  return <Tabs {...props} value={props.value} />;

};

// Dialog - sends event onClose (also manages standard onClose header if desired)

type EventDialogProps = {
  closeEvent?: Event,
  metadata?: string,
  open: boolean,
  onClose?: ((e: any) => void),
  title?: string,
  children?: any,
  [s: string]: any,
};

export const EventDialog = (props: EventDialogProps) => {
  const classes = useStyles(props);
  const [showMessengerOnClose, setShowMessengerOnClose] = React.useState<boolean>();

  const groups = useSelector((state: any) => state.clientStateReducer.groups);
  const email = useSelector((state: any) => state.clientStateReducer.email);
  const uxSettings = useSelector((state: any) => state.clientStateReducer.uxSettings);

  React.useEffect(() => {
    if (props.open) {
      if ((window as any).intercomMessenger) {
        (window as any).Intercom("update", {
          hide_default_launcher: true,
        });
        (window as any).intercomMessenger = false;
        setShowMessengerOnClose(true);
      }
    }
  }, [props.open]);

  const handleClose = (e: any) => {
    if (showMessengerOnClose) {
      (window as any).Intercom("update", {
        hide_default_launcher: false,
      });
      (window as any).intercomMessenger = false;
    }

    trackEventLegacy(email, groups, props.closeEvent, props.metadata);
    props.onClose(e);
  };

  const content = (
    <>
      {props.title && (
        <>
          <DialogTitle
            disableTypography
            id="simple-dialog-title"
            style={{ paddingBottom: 0, flex: "unset" }}
          >
            <Typography variant="h2" className={classes.title}>{props.title}</Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={props.closeEvent ? (props.onClose ? handleClose : undefined) : props.onClose}>
              <FontAwesomeIcon icon={faXmark} />
            </IconButton>
          </DialogTitle>
        </>
      )}
      {props.children}
    </>
  );

  if (props.closeEvent) {
    const forwardedProps = { ...props };

    delete forwardedProps.closeEvent;
    delete forwardedProps.includeHeader;

    return (
      <Dialog {...forwardedProps} disableEnforceFocus={uxSettings?.disableEnforceFocus} onClose={props.onClose ? handleClose : null} open={props.open}>
        {content}
      </Dialog>
    );
  }

  return (
    <Dialog {...props} disableEnforceFocus={uxSettings?.disableEnforceFocus} open={props.open}>
      {content}
    </Dialog>
  );

};

type EventConfirmDialogProps = {
  open: boolean,
  title: string,
  description: string,
  secondaryCheck?: string,
  event?: Event,
  yesText?: string,
  noText?: string,
  onClose: (() => void),
  onOK: ((checked?: boolean) => void),
};

export const EventConfirmDialog = (props: EventConfirmDialogProps) => {
  const groups = useSelector((state: any) => state.clientStateReducer.groups);
  const email = useSelector((state: any) => state.clientStateReducer.email);
  const { event, onOK, onClose, ...otherProps } = props;

  const onOkClick = () => {
    if (props.event) {
      trackEventLegacy(email, groups, props.event, "ok");
    }
    onOK();
  };

  const onCloseClick = () => {
    if (props.event) {
      trackEventLegacy(email, groups, props.event, "cancel");
    }
    onClose();
  };

  const forwardedProps = { ...otherProps };

  return (
    <ConfirmDialog
      {...otherProps}
      onOK={onOkClick}
      onClose={onCloseClick}
    />
  );
};

type AlertProps = {
  open: boolean,
  title: string,
  event?: Event,
  description: any,
  onClose: any,
};

export const EventAlert = (props: AlertProps) => {

  const groups = useSelector((state: any) => state.clientStateReducer.groups);
  const email = useSelector((state: any) => state.clientStateReducer.email);
  const { event, onClose, ...otherProps } = props;

  const handleClose = () => {
    if (props.event) {
      trackEventLegacy(email, groups, props.event);
    }
    onClose();
  };

  return (
    <Alert
      {...otherProps}
      onClose={handleClose}
    />
  );
};

interface EventLoadingProps {
  title: string;
}

export const EventLoading = (props: EventLoadingProps) => {
  const groups = useSelector((state: any) => state.clientStateReducer.groups);
  const email = useSelector((state: any) => state.clientStateReducer.email);

  return (
    <Loading
      title={props.title}
      delay={30000}
      onLongLoad={() => trackEventLegacy(email, groups, Event.longLoad)}
    />
  );
};

EventDialog.defaultProps = {
  includeHeader: true,
};
