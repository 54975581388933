import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { Address as LUAddress, Parent } from "@legup/legup-model";

import { Address, Alert, LimitTextField, PhoneNumbers } from "@legup/legup-react-components";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";

import { Event } from "../../infra/tracking";
import { emailValidation, isInputFeildEmpty, zipcodeValidation } from "../../infra/utils";
import strings from "../../infra/constants/strings";
import { addParent, updateParent } from "../../actions/parentActions";
import { EventButton, EventConfirmDialog } from "../common/EventControls";

const mapStateToProps = (state: any) => ({
  addedParentId: state.parentReducer.addedParentId,
  parentList: state.parentReducer.parentList,
  updateSuccess: state.parentReducer.updateSuccess,
});

const mapDispatchToProps = (dispatch: any) => ({
  onAddParent: (parent: Parent, children: string[] | undefined) => {
    dispatch(addParent(parent, children));
  },
  onUpdateParent: (parent: Parent) => {
    dispatch(updateParent(parent));
  },
});

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  divider: {
    marginTop: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  labelBold: {
    fontWeight: 700,
  },
  address: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

type EditParentProps = {
  addedParentId?: string;
  name: string;
  parentList?: Parent[];
  navigators?: string[];
  updateSuccess?: boolean;
  onAddParent: (parent: Parent, children: string[] | undefined) => void;
  onUpdateParent: (parent: Parent) => void;
  onSave?: (parent_id: string) => void;
  parent: Parent;
  primaryParent?: Parent;
  secondParent?: boolean;
  addingParent: boolean;
  noChangeEmail?: boolean;
  noSaveButton?: boolean;
  noAddress?: boolean;
  promptTextOptIn?: boolean;
  promptEmailChange?: boolean;
  onChange?: (parent: Parent, field: string) => void;
  requiredFields?: Array<"parentname" | "email" | "phone" | "address">;
  highlightRequired?: boolean;
  showSpouse: boolean;
  readOnly?: boolean;
  classes: any;
  waitListParent: any;
  waitListChild: any;
  validation?: boolean;
};

type EditParentState = {
  parent: Parent;
  requiredAlert: boolean;
  usePrimaryParentAddress: boolean;
  validation: boolean;
  changedEmail: boolean;
  optInPrompt: "none" | "showing" | "shown";
  emailPrompt: "none" | "showing" | "shown";
};

class EditParent extends React.Component<EditParentProps, EditParentState> {
  static defaultProps = {
    parentList: [],
    requiredFields: ["parentname", "email", "phone", "address"],
  };

  constructor(props: EditParentProps) {
    super(props);

    this.state = {
      parent: this.props.parent,
      requiredAlert: false,
      usePrimaryParentAddress: !!this.props.primaryParent?.getAddress()?.equals(this.props.parent?.getAddress()),
      validation: false,
      changedEmail: false,
      optInPrompt: "none",
      emailPrompt: "none",
    };
  }

  componentDidMount() {
    if (this.props.waitListParent) {
      this.setState({ parent: this.props.waitListParent });
    }
  }

  componentDidUpdate(prevProps: EditParentProps, prevState: EditParentState) {
    if (prevProps.parent !== this.props.parent) {
      this.setState({ parent: this.props.parent });
    }
    if (this.props.addedParentId && (prevProps.addedParentId !== this.props.addedParentId)) {
      if (this.props.onSave) {
        this.props.onSave(this.props.addedParentId);
      }
    }
    if (this.props.updateSuccess && (prevProps.updateSuccess !== this.props.updateSuccess)) {
      if (this.props.onSave) {
        this.props.onSave(this.props.parent.getId());
      }
    }
    if (prevProps.waitListParent !== this.props.waitListParent) {
      this.setState({ parent: this.props.waitListParent });
    }
  }

  // If label is given, we will update label to indicate required
  // If no label is present, we return a boolean as to whether the field is required
  classLabel(label: string | undefined, field: string): string | boolean {
    if (!this.props.highlightRequired) {
      return label || false;
    }

    const mapping: { [s: string]: "parentname" | "email" | "phone" | "address" } = {
      first_name: "parentname",
      last_name: "parentname",
      email: "email",
      phonenumbers: "phone",
      address: "address",
    };

    if (mapping[field] && (this.props.requiredFields.indexOf(mapping[field]) > -1)) {
      return label ? `${label}${strings.parentDetails.required}` : true;
    }

    return label || false;
  }

  onRequiredAlertClose() {
    this.setState({ requiredAlert: false });
  }

  onSaveParent() {
    // Data validation?
    const parent: Parent = this.state.parent;

    // parentname, email, phone, address
    if (((this.props.requiredFields.indexOf("email") > -1) && !emailValidation(parent.email))
      || ((this.props.requiredFields.indexOf("parentname") > -1) && (!parent.first_name || !parent.last_name))
      || ((this.props.requiredFields.indexOf("phone") > -1) && ((!parent.phone_number && !parent.work_phone_number && !parent.cell_phone_number) || !parent.primary_phone))
      || ((this.props.requiredFields.indexOf("address") > -1) && (!zipcodeValidation(parent.getAddress().getPostalCode()) ||
        isInputFeildEmpty(parent.getAddress().getStreetAddress1()) ||
        isInputFeildEmpty(parent.getAddress().getCity()) ||
        isInputFeildEmpty(parent.getAddress().getState())))) {
      this.setState({ requiredAlert: true, validation: true });
    }
    else if (this.props.promptTextOptIn && (this.state.optInPrompt === "none") && parent.cell_phone_number && !parent.can_text) {
      // Let's make sure they know to opt in for text messages!
      this.setState({ optInPrompt: "showing" });
    }
    else if (this.props.promptEmailChange && (this.state.emailPrompt === "none") && this.state.changedEmail) {
      // Make sure they understand what happens if they change their email address
      this.setState({ emailPrompt: "showing" });
    }
    else if (this.props.addingParent) {
        // If this is a new parent and they had children set, then we'll associate them when we save
        // If we have a navigator ID, then we will associate this parent with that navigator as well
      parent.setNavigators(this.props.navigators);
      this.props.onAddParent(parent, (parent.children ? parent.children.map(c => c.getId()) : undefined));
    }
    else {
      this.props.onUpdateParent(parent);
    }
  }

  onUsePrimaryParentAddressChange(e: any) {
    const parent = this.state.parent;

    if (this.props.parent && this.props.primaryParent && e.target.checked) {
      parent.setAddress(this.props.primaryParent.getAddress());
    }

    this.setState({ parent, usePrimaryParentAddress: e.target.checked });
  }

  onConfirmOptIn(optIn: boolean) {
    const parent: Parent = this.state.parent;
    if (optIn) {
      parent.can_text = true;
    }

    // Mark the dialog as shown and continue the save
    this.setState({ optInPrompt: "shown", parent }, () => this.onSaveParent());
  }

  onConfirmEmailChange(optIn: boolean) {
    // Mark the dialog as shown and continue the save
    if (optIn) {
      this.setState({ emailPrompt: "shown" }, () => this.onSaveParent());
    }
    else {
      this.setState({ emailPrompt: "none" });
    }
  }

  onParentFieldChange(e: any) {
    const stateUpdate: any = { parent: this.state.parent };

    if (!stateUpdate.parent) {
      stateUpdate.parent = new Parent();
    }
    stateUpdate.parent[e.target.name] = e.target.value;
    if (e.target.name === "email") {
      stateUpdate.changedEmail = true;
    }
    this.setState(stateUpdate);

    if (this.props.onChange) {
      this.props.onChange(stateUpdate.parent, e.target.name);
    }
  }

  onFormatParentData(e: any) {
    const stateUpdate = { parent: this.state.parent };

    stateUpdate.parent[e.target.name] = e.target.value.trim();
    this.setState(stateUpdate);
  }

  onPhoneNumberChange(home_phone_number: string | undefined, work_phone_number: string | undefined,
    cell_phone_number: string | undefined, can_text: boolean | undefined,
    primary_phone: "home" | "work" | "cell" | null) {
    const p = this.state.parent ? this.state.parent.copy() : new Parent();
    let changed: string | undefined;

    if (primary_phone !== p.primary_phone) {
      p.setPrimaryPhone(primary_phone);
      changed = "primary_phone";
    }
    if (home_phone_number !== p.phone_number) {
      p.setPhoneNumber(home_phone_number);
      changed = "phone_number";
    }
    if (work_phone_number !== p.work_phone_number) {
      p.setWorkPhoneNumber(work_phone_number);
      changed = "work_phone_number";
    }
    if (cell_phone_number !== p.cell_phone_number) {
      p.setCellPhoneNumber(cell_phone_number);
      changed = "cell_phone_number";
    }
    if (can_text !== p.can_text) {
      p.setCanText(can_text);
      changed = "can_text";
    }

    if (changed) {
      this.setState({ parent: p });
      if (this.props.onChange) {
        this.props.onChange(p, changed);
      }
    }
  }

  onAddressChange(address: LUAddress, field: string) {
    const stateUpdate = { parent: this.state.parent, usePrimaryParentAddress: false };

    if (!stateUpdate.parent) {
      stateUpdate.parent = new Parent();
    }
    stateUpdate.parent.setAddress(address);
    this.setState(stateUpdate);

    if (this.props.onChange) {
      this.props.onChange(stateUpdate.parent, field);
    }
  }

  renderParentDetails() {
    const parent: Parent = this.state.parent;
    const address: LUAddress = parent.address;
    const { classes } = this.props;

    let errorFirstName = false;
    let errorLastName = false;
    let errorEmail = false;
    let errorPhone = false;
    let errorStreet = false;
    let errorCity = false;
    let errorState = false;
    let errorzip = false;

    if (this.state.validation || this.props.validation) {
      errorEmail = (this.props.requiredFields.indexOf("email") > -1) && !emailValidation(parent.email);
      errorzip = (this.props.requiredFields.indexOf("address") > -1) && !zipcodeValidation(parent.getAddress().getPostalCode());
      errorStreet = (this.props.requiredFields.indexOf("address") > -1) && isInputFeildEmpty(parent.getAddress().getStreetAddress1());
      errorCity = (this.props.requiredFields.indexOf("address") > -1) && isInputFeildEmpty(parent.getAddress().getCity());
      errorState = (this.props.requiredFields.indexOf("address") > -1) && isInputFeildEmpty(parent.getAddress().getState());
      errorFirstName = (this.props.requiredFields.indexOf("parentname") > -1) && !parent.first_name;
      errorLastName = (this.props.requiredFields.indexOf("parentname") > -1) && !parent.last_name;
      errorPhone = (this.props.requiredFields.indexOf("phone") > -1) && ((!parent.phone_number && !parent.work_phone_number && !parent.cell_phone_number) || !parent.primary_phone);
    }

    const alert = this.state.requiredAlert && (<Alert title={strings.requiredFields.title} description={strings.requiredFields.message} open={this.state.requiredAlert} onClose={this.onRequiredAlertClose.bind(this)} />);

    return (
      <>
        {alert}
        <Grid container spacing={2} className="fs-mask parentform">
          <Grid item xs={12}>
            <LimitTextField
              error={errorFirstName}
              id={`${this.props.name}_first_name`}
              key={`${this.props.name}_first_name`}
              name="first_name"
              className="fs-unmask"
              variant="outlined"
              size="small"
              fullWidth
              label={this.classLabel(strings.parentDetails.firstName, "first_name")}
              value={parent.first_name}
              disabled={this.props.readOnly}
              limit={128}
              showCount={false}
              onChange={this.onParentFieldChange.bind(this)}
              onBlur={this.onFormatParentData.bind(this)}
            />
          </Grid>
          <Grid item xs={12}>
            <LimitTextField
              error={errorLastName}
              id={`${this.props.name}_last_name`}
              key={`${this.props.name}_last_name`}
              name="last_name"
              className="fs-unmask"
              variant="outlined"
              size="small"
              fullWidth
              label={this.classLabel(strings.parentDetails.lastName, "last_name")}
              value={parent.last_name}
              disabled={this.props.readOnly}
              limit={128}
              showCount={false}
              onChange={this.onParentFieldChange.bind(this)}
              onBlur={this.onFormatParentData.bind(this)}
            />
          </Grid>
          <Grid item xs={12}>
            <LimitTextField
              error={errorEmail}
              id={`${this.props.name}_email`}
              key={`${this.props.name}_email`}
              name="email"
              variant="outlined"
              size="small"
              fullWidth
              label={this.classLabel(strings.parentDetails.email, "email")}
              value={parent.email || ""}
              disabled={this.props.readOnly || this.props.noChangeEmail}
              limit={128}
              showCount={false}
              onChange={this.onParentFieldChange.bind(this)}
              onBlur={this.onFormatParentData.bind(this)}
            />
            <div style={{ color: "Red" }}>
              { errorEmail ? strings.errorMessages.emailValidationError : null }
            </div>
          </Grid>
          {this.classLabel(undefined, "phonenumber") && (
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.labelBold}>{strings.parentDetails.requiredPhone}</Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <PhoneNumbers
              error={errorPhone}
              key={`${this.props.name}_phone_numbers`}
              name={this.props.name}
              display={["home", "work", "cell"]}
              readOnly={this.props.readOnly}
              phone_number={parent.phone_number}
              work_phone_number={parent.work_phone_number}
              cell_phone_number={parent.cell_phone_number}
              can_text={parent.can_text}
              showPrimary
              primary_phone={parent.primary_phone}
              onChange={this.onPhoneNumberChange.bind(this)}
            />
          </Grid>
        </Grid>
        {this.props.secondParent && (
          <Grid item xs={12}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={this.state.usePrimaryParentAddress}
                  onChange={this.onUsePrimaryParentAddressChange.bind(this)}
                  value="parent-address"
                  name="parent-address"
                  color="primary"
                  disabled={this.props.readOnly}
                  className={classes.addressCheck}
                />
              )}
              label={strings.childDetails.useParentAddress}
            />
          </Grid>
        )}
        {!this.props.noAddress && (
          <>
            {this.classLabel(undefined, "address") && (
              <>
                <div style={{ height: "16px" }} />
                <Grid item xs={12}>
                  <Typography variant="body1" className={classes.labelBold}>{strings.parentDetails.requiredAddress}</Typography>
                </Grid>
                <div style={{ height: "16px" }} />
              </>
            )}
            <div className={classes.address}>
              <Address
                address={address}
                name={this.props.name}
                readOnly={this.props.readOnly}
                onChange={this.onAddressChange.bind(this)}
                errorStreetAddress={errorStreet}
                errorState={errorState}
                zipcodeValidationError={errorzip}
                errorCity={errorCity}
              />
            </div>
            <Grid item xs={12}>
              <LimitTextField
                id={`${this.props.name}_company`}
                key={`${this.props.name}_company`}
                name="company"
                variant="outlined"
                size="small"
                fullWidth
                disabled={this.props.readOnly}
                limit={128}
                showCount={false}
                label={strings.parentDetails.company}
                value={parent.company || ""}
                onChange={this.onParentFieldChange.bind(this)}
                className="mt-1"
              />
            </Grid>
          </>
        )}
      </>
    );
  }

  render() {
    const { classes } = this.props;
    if (!this.state.parent) {
      return null;
    }

    return (
      <div>
        {this.renderParentDetails()}
        {this.props.noSaveButton ? null : (
          <EventButton
            className={classes.button}
            event={Event.familySaveParent}
            metadata={this.props.addingParent ? "add" : this.state.parent.getId()}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => this.onSaveParent()}
          >
            {this.props.addingParent
              ? strings.parentDetails.buttonAdd
              : strings.parentDetails.buttonSave}
          </EventButton>
        )}
        <EventConfirmDialog
          open={this.state.optInPrompt === "showing"}
          title={strings.parentDetails.noTextTitle}
          event={Event.familyConfirmTextOptin}
          description={strings.parentDetails.noTextText}
          onClose={() => this.onConfirmOptIn(false)}
          onOK={() => this.onConfirmOptIn(true)}
        />
        <EventConfirmDialog
          open={this.state.emailPrompt === "showing"}
          title={strings.parentDetails.changeEmailTitle}
          event={Event.familyConfirmChangeEmail}
          description={strings.parentDetails.changeEmailWarning}
          onClose={() => this.onConfirmEmailChange(false)}
          onOK={() => this.onConfirmEmailChange(true)}
        />
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(EditParent);
