import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Alert, FamilySubsidies, LimitTextField } from "@legup/legup-react-components";

import { makeStyles } from "@material-ui/core/styles";

import { Address, CenterQuestion, Child, Parent, WaitlistSpot } from "@legup/legup-model";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-light-svg-icons";

import { EventLoading } from "../common/EventControls";
import { listQuestions, updateCenterQuestions } from "../../actions/centerActions";
import { emailValidation, zipcodeValidation } from "../../infra/utils";
import { Event } from "../../infra/tracking";
import strings from "../../infra/constants/strings";

import FormSection from "../common/FormSection";
import CenterQuestions from "../Questions/CenterQuestions";
import EditParent from "../Family/EditParent";
import EditChild from "../Family/EditChild";
import SpotDates from "../Waitlist/SpotDates";
import AnswerQuestions from "../Questions/AnswerQuestions";
import Helper from "../common/Helper";

export interface CenterFormResult {
  parents: Parent[];
  child: Child;
  spot: WaitlistSpot;
  customQuestions?: any[];
}

const eventMap = {
  "parent.first_name.0": Event.formParentFirstName,
  "parent.last_name.0": Event.formParentLastName,
  "parent.email.0": Event.formParentEmail,
  "parent.phone_number.0": Event.formParentPhone,
  "parent.work_phone_number.0": Event.formParentWorkPhone,
  "parent.cell_phone_number.0": Event.formParentCellPhone,
  "parent.can_text.0": Event.formParentCanText,
  "parent.primary_phone.0": Event.formParentPrimaryPhone,
  "parent.company.0": Event.formParentCompany,
  "parent.street_address_1.0": Event.formParentAddress1,
  "parent.street_address_2.0": Event.formParentAddress2,
  "parent.city.0": Event.formParentCity,
  "parent.state.0": Event.formParentState,
  "parent.postal_code.0": Event.formParentZip,
  "parent.subsidy.0": Event.formParentSubsidy,
  "parent.first_name.1": Event.formParent2FirstName,
  "parent.last_name.1": Event.formParent2LastName,
  "parent.email.1": Event.formParent2Email,
  "parent.phone_number.1": Event.formParent2Phone,
  "parent.work_phone_number.1": Event.formParent2WorkPhone,
  "parent.cell_phone_number.1": Event.formParent2CellPhone,
  "parent.can_text.1": Event.formParent2CanText,
  "parent.primary_phone.1": Event.formParent2PrimaryPhone,
  "parent.company.1": Event.formParent2Company,
  "parent.street_address_1.1": Event.formParent2Address1,
  "parent.street_address_2.1": Event.formParent2Address2,
  "parent.city.1": Event.formParent2City,
  "parent.state.1": Event.formParent2State,
  "parent.postal_code.1": Event.formParent2Zip,
  "parent.subsidy.1": Event.formParent2Subsidy,
  "child.gender": Event.formChildGender,
  "child.first_name": Event.formChildFirstName,
  "child.last_name": Event.formChildLastName,
  "child.trying": Event.formChildTrying,
  "child.birth_date": Event.formChildBirthdate,
  "child.sameaddress": Event.formChildSameAddress,
  "child.street_address_1": Event.formChildAddress1,
  "child.street_address_2": Event.formChildAddress2,
  "child.city": Event.formChildCity,
  "child.state": Event.formChildState,
  "child.postal_code": Event.formChildZip,
  "spot.preferred": Event.formSpotEnrollment,
  "spot.comments": Event.formSpotComments,
  "spot.referral": Event.formSpotReferral,
  expandparent1: Event.formParent1Expand,
  expandparent2: Event.formParent2Expand,
  expandchild: Event.formChildExpand,
  expandsubsidy: Event.formSubsidyExpand,
  expandquestions: Event.formQuestionsExpand,
  expandspot: Event.formSpotExpand,
  customanswer: Event.formCustomAnswer,
  alertrequired: Event.formAlertRequired,
  alertsameemail: Event.formAlertSameEmail,
};

const useStyles = makeStyles(theme => ({
  card: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  closeButton: {
    position: "absolute" as "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    "& path": {
      fill: theme.palette.grey[500],
    },
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  sectionTitle: {
    fontSize: 20,
    fontWeight: 500,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: 4,
  },
  hideButton: {
    display: "none",
  },
  toggleIcon: {
    display: "inline-block",
  },
}));

type CenterFormProps = {
  saveButtonRef?: any,
  center_id?: string | undefined,
  questionList?: any[],
  questionSectionHeader?: string,
  availableSubsidies?: Array<{code: string, name: string}>,
  child?: Child,
  parents?: Parent[],
  spot?: WaitlistSpot,
  form: "waitlist" | "deposit",
  requiredFields: Array<"email" | "phone" | "address" | "parentname" | "questions">,
  highlightRequired?: boolean,
  forceParentEmail?: string,
  readOnly?: boolean,
  allowEditQuestions?: boolean,
  allowChangeAddDate?: boolean,
  showSpotSection?: boolean,
  disallowOther?: boolean,
  collapsableSections?: Array<"parent" | "parent2" | "child" | "subsidy" | "questions" | "spot">,
  onEvent?: (event: Event, metadata: string | undefined) => void,
  onChange?: (result: CenterFormResult) => void,
  onSave?: (result: CenterFormResult) => void,
};

export const CenterForm = (props: CenterFormProps) => {
  const copyParents = (): Parent[] => {
    const firstParent = props.parents ? props.parents[0].copy() : new Parent();
    const secondParent = props.parents?.length > 1 ? props.parents[1].copy() : new Parent();
    if (props.forceParentEmail) {
      firstParent.setEmail(props.forceParentEmail);
    }

    return [firstParent, secondParent];
  };

  const classes = useStyles(props);
  const [parents, setParents] = React.useState(copyParents());
  const [child, setChild] = React.useState(props.child ? props.child.copy() : new Child());
  const [answers, setAnswers] = React.useState(props.spot ? props.spot.getCustomAnswers() : undefined);
  const [missingAnswers, setMissingAnswers] = React.useState(false);
  const [invalidParent1, setInvalidParent1] = React.useState(false);
  const [invalidParent2, setInvalidParent2] = React.useState(false);
  const [spot, setSpot] = React.useState(props.spot ? props.spot.copy() : new WaitlistSpot());
  const [alertMessage, setAlertMessage] = React.useState<string>();
  const [showEditQuestions, setShowEditQuestions] = React.useState(false);
  const [newQuestions, setNewQuestions] = React.useState<any[]>();
  const [tempQuestions, setTempQuestions] = React.useState<any[]>();
  const [initialExpandState, setInitialExpandState] = React.useState({
    parent1: !!props.parents?.length && !!props.parents[0]?.getEmail()?.length,
    parent2: (props.parents && props.parents.length > 1)
      ? !!(props.parents[1].getEmail() && props.parents[1].getEmail().length) : false,
    subsidy: !!props.parents?.length && !!props.parents[0]?.getSubsidies()?.length,
    child: !!props.child?.getFirstName()?.length,
    questions: false,
    spot: false,
  });
  const [eventsSent, setEventsSent] = React.useState([]);

  const groups = useSelector((state: any) => state.clientStateReducer.groups);
  const customQuestions = useSelector((state: any) => state.centerReducer.questionList);
  const centerCount = useSelector((state: any) => state.centerReducer.centerCount);
  const uxSettings = useSelector((state: any) => state.clientStateReducer.uxSettings);
  const dispatch = useDispatch();

  React.useEffect(() => {
    // Since you may be able to edit questions, I need to get all questions to save properly
    if (props.center_id) {
      setEventsSent([]);
      if (props.allowEditQuestions || !props.questionList) {
        dispatch(listQuestions(props.center_id, true, "both"));
      }
    }
  }, [props.center_id]);

  React.useEffect(() => {
    const exp = { ...initialExpandState };
    setParents(copyParents());
    exp.parent1 = !!props.parents?.length && !!props.parents[0]?.getEmail()?.length;
    exp.parent2 = ((props.parents && props.parents.length > 1)
      ? !!(props.parents[1].getEmail() && props.parents[1].getEmail().length) : false);
    setInitialExpandState(exp);
  }, [props.parents]);

  React.useEffect(() => {
    setChild(props.child ? props.child.copy() : new Child());
  }, [props.child]);

  React.useEffect(() => {
    setSpot(props.spot ? props.spot.copy() : new WaitlistSpot());
    setAnswers(props.spot ? props.spot.getCustomAnswers() : undefined);
  }, [props.spot]);

  React.useEffect(() => {
    const qs = [];
    (customQuestions || []).forEach(q => {
      const question = new CenterQuestion();
      question.buildFromJSON(q);
      qs.push(question);
    });
    setNewQuestions(qs);
  }, [customQuestions]);

  const sendEvent = (eventName: string, metadata: string | undefined) => {
    if (props.onEvent) {
      // Let's first check if this event has already been sent - we only send each one once
      const match = metadata ? `${eventName}.${metadata}` : eventName;
      if (eventsSent.indexOf(match) === -1) {
        const newEvents = eventsSent.concat([match]);
        setEventsSent(newEvents);
        props.onEvent(eventMap[eventName], metadata);
      }
    }
  };

  const onParentChange = (parent: Parent, field: string, idx: number) => {
    const newParents = [].concat(parents);
    newParents[idx] = parent;
    setParents(newParents);

    sendEvent(`parent.${field}.${idx}`, undefined);
    if (props.onChange) {
      const result: CenterFormResult = { parents: newParents, child, spot, customQuestions: customQuestions || props.questionList };
      props.onChange(result);
    }
  };

  const onChildChange = (c: Child, field: string) => {
    setChild(c);
    sendEvent(`child.${field}`, undefined);
    if (props.onChange) {
      const result: CenterFormResult = { parents, child: c, spot, customQuestions: customQuestions || props.questionList };
      props.onChange(result);
    }
  };

  const onChangeSubsidies = (subsidies: string[]) => {
    const newParents = parents;
    newParents[0] = parents[0].copy();
    newParents[0].setSubsidies(subsidies);
    setParents(newParents);

    sendEvent("parent.subsidy.0", undefined);
    if (props.onChange) {
      const result: CenterFormResult = { parents: newParents, child, spot, customQuestions: customQuestions || props.questionList };
      props.onChange(result);
    }
  };

  const onChangeAnswers = (a: any[], referral: string, p: number) => {
    // Let's see which one changed, so we can send over metadata
    if (props.onEvent) {
      if (spot.getReferral() !== referral) {
        sendEvent("spot.referral", undefined);
      }

      let changed;
      a.forEach(ans => {
        if (answers) {
          const oldAns = answers.find(x => x.id === ans.id);
          if (!oldAns || (oldAns.answer !== ans.answer)) {
            changed = ans.id;
          }
        }
        else if (ans.answer && ans.answer.length) {
          changed = ans.id;
        }
      });

      if (changed) {
        const qu = (customQuestions || props.questionList || []).find(q => q.id === changed);
        if (qu) {
          sendEvent("customanswer", qu.question);
        }
      }
    }

    const newSpot = spot.copy();
    setAnswers(a);
    newSpot.setReferral(referral);
    newSpot.setPriority(p);
    newSpot.setCustomAnswers(a);
    setSpot(newSpot);

    if (props.onChange) {
      const result: CenterFormResult = { parents, child, spot: newSpot, customQuestions: customQuestions || props.questionList };
      props.onChange(result);
    }
  };

  const onSpotDateChange = (date: Date, type: "add" | "preferred") => {
    const newSpot = spot.copy();
    if (type === "add") {
      newSpot.setAddDate(date);
    }
    else if (type === "preferred") {
      newSpot.setPreferredDate(date);
    }

    setSpot(newSpot);
    sendEvent(`spot.${type}`, undefined);
    if (props.onChange) {
      const result: CenterFormResult = { parents, child, spot: newSpot, customQuestions: customQuestions || props.questionList };
      props.onChange(result);
    }
  };

  const onSpotFieldChange = (e: any) => {
    const newSpot = spot.copy();
    newSpot[e.target.name] = e.target.value;
    setSpot(newSpot);

    sendEvent(`spot.${e.target.name}`, undefined);
    if (props.onChange) {
      const result: CenterFormResult = { parents, child, spot: newSpot, customQuestions: customQuestions || props.questionList };
      props.onChange(result);
    }
  };

  const handleExpanded = (expanded: boolean, section: string) => {
    sendEvent(`expand${section}`, undefined);
  };

  const onEditQuestions = (e: any) => {
    if (groups === "provider") {
      setAlertMessage(strings.waitlists.questionCaption);
    }
    else {
      setTempQuestions(undefined);
      setShowEditQuestions(true);
    }
  };

  const onCloseEditQuestions = () => {
    setShowEditQuestions(false);
  };

  const onChangeQuestions = (q: any) => {
    setTempQuestions(q);
  };

  const onSaveQuestions = (e: any) => {
    if (tempQuestions) {
      // OK, save these questions then reflect within the form!
      setAnswers(undefined);
      setNewQuestions(tempQuestions);
      dispatch(updateCenterQuestions(props.center_id, tempQuestions));
    }

    setShowEditQuestions(false);
  };

  const isParentValid = (parent: Parent): boolean => {
    // Always the optimist
    let valid: boolean = true;

    if (props.requiredFields.indexOf("parentname") > -1) {
      // You need a first and last name
      valid = valid && !!parent.getFirstName() && !!parent.getLastName();
    }
    if (props.requiredFields.indexOf("email") > -1) {
      // Verify email
      valid = valid && emailValidation(parent.getEmail());
    }
    if (props.requiredFields.indexOf("phone") > -1) {
      valid = valid && ((parent.getPhoneNumber()?.length > 2) || (parent.getWorkPhoneNumber()?.length > 2) || (parent.getCellPhoneNumber()?.length > 2));
    }
    if (props.requiredFields.indexOf("address") > -1) {
      const zipcodeValidationError = !zipcodeValidation(parent.getAddress().getPostalCode());
      valid = (valid && !zipcodeValidationError && !!parent.getAddress().getStreetAddress1() &&
        !!parent.getAddress().getCity() && !!parent.getAddress().getState());
    }

    return valid;
  };

  const onSave = (e: any) => {
    // Do data validation
    const p1 = !isParentValid(parents[0]);
    const p2 = !parents[1].isEmpty() && !isParentValid(parents[1]);

    setInvalidParent1(p1);
    setInvalidParent2(p2);

    if (p1 || p2) {
      sendEvent("alertrequired", undefined);
      setAlertMessage(strings.requiredFields.message);

      return;
    } if (parents[1].getEmail() && (parents[0].getEmail() === parents[1].getEmail())) {
      sendEvent("alertsameemail", undefined);
      setAlertMessage(strings.requiredFields.sameEmail);

      return;
    }

    if (props.form === "waitlist") {
      // If no spot preferred date it means use this month
      if (!spot.getPreferredDate()) {
        const date = new Date();
        spot.setPreferredDate(new Date(date.getFullYear(), date.getMonth(), 1));
      }
    }

    // Make sure all required custom questions are answered
    const questions = customQuestions || props.questionList;
    if (props.requiredFields.indexOf("questions") > -1) {
      let requiredQuestionsAnswered = true;
      if (questions) {
        const answeredIds = (answers) ? answers.filter(a => a.answer.length > 0).map(a => a.id) : [];
        questions.forEach(q => {
          const required = (props.form === "deposit") ? q.deposit_required : q.required;
          if (required && (answeredIds.indexOf(q.id) === -1)) {
            requiredQuestionsAnswered = false;
          }
        });
      }
      setMissingAnswers(!requiredQuestionsAnswered);
      if (!requiredQuestionsAnswered) {
        setAlertMessage(strings.requiredFields.message);

        return;
      }
    }

    spot.setCustomAnswers(answers);

    // If the child has the same address as BOTH parents, then we will
    // not bother to save a child address - they'll move with the parent(s)
    const c: Child = child.copy();
    const sameAddress: boolean = child.getAddress().equals(parents[0].getAddress())
      && (!parents[1].getEmail() || parents[0].getAddress().equals(parents[1].getAddress()));
    if (sameAddress) {
      c.setAddress(new Address());
      setChild(c);
    }

    // Should we do save here or let parent?
    const result: CenterFormResult = { parents, child: c, spot, customQuestions: questions };
    props.onSave(result);
  };

  const renderParent1 = () => (
    <FormSection
      key="section-parent1"
      title={strings.centerForm.sectionParent1}
      expandable={props.collapsableSections?.indexOf("parent") > -1}
      initialExpandState={initialExpandState.parent1}
      onExpand={(expanded: boolean) => handleExpanded(expanded, "parent")}
    >
      <EditParent
        parent={parents[0]}
        name="parent1"
        key="parent1"
        noSaveButton
        addingParent
        noChangeEmail={!!props.forceParentEmail}
        readOnly={props.readOnly}
        requiredFields={props.requiredFields}
        highlightRequired={props.highlightRequired}
        validation={invalidParent1}
        onChange={(p, field) => onParentChange(p, field, 0)}
      />
    </FormSection>
  );

  const renderParent2 = () => (
    <FormSection
      key="section-parent2"
      title={strings.centerForm.sectionParent2}
      expandable={props.collapsableSections?.indexOf("parent2") > -1}
      initialExpandState={initialExpandState.parent2}
      onExpand={(expanded: boolean) => handleExpanded(expanded, "parent2")}
    >
      <EditParent
        parent={parents[1]}
        primaryParent={parents[0]}
        name="parent2"
        key="parent2"
        secondParent
        noSaveButton
        addingParent
        readOnly={props.readOnly}
        requiredFields={props.requiredFields}
        highlightRequired={props.highlightRequired && (props.collapsableSections?.indexOf("parent2") > -1)}
        validation={invalidParent2}
        onChange={(p, field) => onParentChange(p, field, 1)}
      />
    </FormSection>
  );

  const renderChild = () => (
    <FormSection
      key="section-child"
      title={strings.centerForm.sectionChild}
      expandable={props.collapsableSections?.indexOf("child") > -1}
      initialExpandState={initialExpandState.child}
      onExpand={(expanded: boolean) => handleExpanded(expanded, "child")}
    >
      <EditChild
        parents={parents}
        child={child}
        key="child"
        useSameAddress
        noSaveButton
        readOnly={props.readOnly}
        disallowOther={props.disallowOther}
        onChange={onChildChange}
      />
    </FormSection>
  );

  const renderSubsidies = () => {
    if (!props.availableSubsidies || !props.availableSubsidies.length) {
      return null;
    }

    return (
      <FormSection
        key="section-subsidy"
        title={strings.centerForm.sectionSubsidies}
        expandable={props.collapsableSections?.indexOf("subsidy") > -1}
        initialExpandState={initialExpandState.subsidy}
        onExpand={(expanded: boolean) => handleExpanded(expanded, "subsidy")}
      >
        <FamilySubsidies
          key="subsidies"
          availableSubsidies={props.availableSubsidies}
          parentSubsidies={parents[0].getSubsidies()}
          onChange={onChangeSubsidies}
        />
      </FormSection>
    );
  };

  const renderSpot = () => {
    if (!props.showSpotSection) {
      return null;
    }

    return (
      <FormSection
        key="section-spot"
        title={strings.centerForm.sectionSpot}
        expandable={props.collapsableSections?.indexOf("spot") > -1}
        initialExpandState={initialExpandState.spot}
        onExpand={(expanded: boolean) => handleExpanded(expanded, "spot")}
      >
        <>
          <SpotDates
            addDate={spot.getAddDate()}
            key="dates"
            readOnly={props.readOnly}
            preferredDate={spot.getPreferredDate()}
            onChange={onSpotDateChange}
            canChangeAddDate={props.allowChangeAddDate}
          />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.formControl}>{strings.centerForm.commentsLabel}</Typography>
            </Grid>
            <Grid item xs={12}>
              <LimitTextField
                id="comments"
                name="comments"
                key="comments"
                variant="outlined"
                size="small"
                label={strings.centerForm.comments}
                fullWidth
                multiline
                rows="3"
                disabled={props.readOnly}
                limit={500}
                value={spot.getComments()}
                onChange={onSpotFieldChange}
              />
            </Grid>
          </Grid>
        </>
      </FormSection>
    );
  };

  const renderQuestions = () => {
    const qs = (props.allowEditQuestions ? (newQuestions || []) : (customQuestions || props.questionList || []))
      .filter(q => (q.form === "both") || (q.form === props.form));

    if (!props.allowEditQuestions && !qs.length) {
      return null;
    }

    const button = (props.allowEditQuestions && groups !== "provider" ? (
      <Button key="editQ" variant="contained" size="small" color="secondary" onClick={onEditQuestions}>
        {strings.centerForm[props.form === "waitlist" ? "buttonEditWaitlistQuestions" : "buttonEditDepositQuestions"]}
      </Button>
    ) : undefined);

    return (
      <>
        <FormSection
          key="section-questions"
          button={button}
          title={props.questionSectionHeader || strings.centerForm.sectionQuestions}
          expandable={props.collapsableSections?.indexOf("questions") > -1}
          initialExpandState={initialExpandState.questions}
          onExpand={(expanded: boolean) => handleExpanded(expanded, "questions")}
        >
          <AnswerQuestions
            questions={qs}
            key="questions"
            initialAnswers={answers}
            referral={spot.getReferral()}
            showTitle={false}
            form={props.form}
            readOnly={props.readOnly}
            onChange={onChangeAnswers}
            error={missingAnswers}
          />
          {props.allowEditQuestions && groups === "provider" && <Helper text={strings.centerForm.editQuestionsNote} />}
        </FormSection>
      </>
    );
  };

  const renderChangeQuestions = () => (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      disableEnforceFocus={uxSettings?.disableEnforceFocus}
      maxWidth="md"
      open={showEditQuestions}
      onClose={onCloseEditQuestions}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {strings.centerForm[(props.form === "waitlist" ? "titleChangeWaitlist" : "titleChangeDeposit")]}
      </DialogTitle>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onCloseEditQuestions}>
        <FontAwesomeIcon icon={faXmark} />
      </IconButton>
      <DialogContent>
        <CenterQuestions
          questions={newQuestions}
          showProviderOption={centerCount > 1}
          form="both"
          onChange={onChangeQuestions}
        />
        <Button className={classes.button} key="add" variant="contained" color="primary" fullWidth onClick={onSaveQuestions}>
          {strings.centerForm.buttonSaveQuestions}
        </Button>
      </DialogContent>
    </Dialog>
  );

  // No form until we get the custom questions back
  if ((props.center_id && !customQuestions) && !props.questionList) {
    return <EventLoading title={strings.loading} />;
  }

  return (
    <>
      {renderParent1()}
      {renderParent2()}
      {renderChild()}
      {renderSubsidies()}
      {renderQuestions()}
      {(props.form === "waitlist") ? renderSpot() : null}
      {renderChangeQuestions()}
      <Button
        key="add"
        variant="contained"
        color="primary"
        fullWidth
        ref={props.saveButtonRef}
        className={classes.hideButton}
        onClick={onSave}
        disabled={props.readOnly}
      >
        Save
      </Button>
      <Alert
        title={strings.alertTitle}
        description={alertMessage}
        open={!!alertMessage}
        onClose={() => setAlertMessage(undefined)}
      />
    </>
  );
};

CenterForm.defaultProps = {
  showSpotSection: true,
  allowChangeAddDate: true,
  collapsableSections: ["parent2"],
};
