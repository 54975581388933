import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-light-svg-icons";

import { kinsideTokens } from "@legup/legup-react-components";

const useStyles = makeStyles(theme => ({
  card: {
    margin: theme.spacing(2),
  },
  sectionTitle: {
    fontSize: 20,
    fontWeight: 500,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: 4,
  },
  toggleIcon: {
    display: "inline-block",
    "& path": {
      fill: kinsideTokens["--viridian-50"],
    },
  },
}));

type FormSectionProps = {
  title: string,
  button?: React.ReactNode,
  expandable?: boolean,
  initialExpandState?: boolean,
  onExpand?: (expanded: boolean) => void,
  children?: any,
};

const FormSection = (props: FormSectionProps) => {
  const classes = useStyles(props);
  const [expanded, setExpanded] = React.useState(props.initialExpandState);
  const [sentEvent, setSentEvent] = React.useState(false);

  const handleExpandSection = (e: any) => {
    if (props.onExpand) {
      props.onExpand(!expanded);
    }

    setExpanded(!expanded);
  };

  if (props.expandable) {
    return (
      <Card elevation={0} key="card-section" className={classes.card}>
        <CardContent>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              {props.button ? (
                <Grid container spacing={4}>
                  <Grid item xs={8}>
                    <Typography variant="h2" className={classes.sectionTitle}>
                      {props.title}
                    </Typography>
                  </Grid>
                  <Grid container item xs={4} justify="flex-end">
                    {props.button}
                  </Grid>
                </Grid>
              ) : (
                <Typography variant="h2" className={classes.sectionTitle}>
                  {props.title}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <IconButton id="expand-parent" className={classes.toggleIcon} onClick={handleExpandSection}>
                {expanded ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
              </IconButton>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" className={classes.divider} />
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            {props.children}
          </Collapse>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card elevation={0} key="card-section" className={classes.card}>
      <CardContent>
        {props.button ? (
          <Grid container spacing={4}>
            <Grid item xs={8}>
              <Typography variant="h2" className={classes.sectionTitle}>{props.title}</Typography>
            </Grid>
            <Grid container item xs={4} justify="flex-end">
              {props.button}
            </Grid>
          </Grid>
        )
          : <Typography variant="h2" className={classes.sectionTitle}>{props.title}</Typography>}
        <Divider variant="fullWidth" className={classes.divider} />
        {props.children}
      </CardContent>
    </Card>
  );
};

export default FormSection;
