import React from "react";

import { CenterQuestion } from "@legup/legup-model";
import { LimitTextField } from "@legup/legup-react-components";

import { Theme, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-light-svg-icons";

import { getQuestionPrioirtyRank, getQuestionType } from "../../infra/utils";
import strings from "../../infra/constants/strings";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
  },
  alignItems: {
    marginTop: 8,
  },
  header: {
    fontSize: "16px",
    fontWeight: "bold",
    cursor: "default",
  },
  closeButton: {
    position: "absolute" as "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    "& path": {
      fill: theme.palette.grey[500],
    },
  },
  formControl: {
    marginTop: theme.spacing(1),
    fullWidth: "true",
    display: "flex",
    wrap: "nowrap",
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  subCheckbox: {
    marginLeft: theme.spacing(4),
  },
  caption: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tip: {
    fontStyle: "italic",
    fontWeight: "bold",
  },
}));

type EditQuestionProps = {
  question: CenterQuestion;
  allQuestions: CenterQuestion[];
  adding?: boolean;
  showProviderOption?: boolean;
  form: "waitlist" | "deposit" | "both";
  open: boolean;
  onOK: (question: CenterQuestion) => void;
  onClose: () => void;
};

const EditQuestion = (props: EditQuestionProps) => {
  const classes = useStyles(props);

  // Calculate priorities
  const calcPriorities = () => {
    const priorities: number[] = [];
    const rankedPriorities = getQuestionPrioirtyRank(props.allQuestions);

    if (props.question && props.question.getChoices()) {
      props.question.getChoices().forEach(c => {
        if (!c.priority || c.priority <= 10) {
          priorities.push(0);
        }
        else {
          priorities.push(1 + rankedPriorities.indexOf(c.priority));
        }
      });
    }

    return priorities;
  };

  const types = ["freeform", "yesno", "multiple", "date", "referral", "html"];
  const [question, setQuestion] = React.useState(props.question ? props.question.copy() : new CenterQuestion());
  const [priorityMap, setPriorityMap] = React.useState(calcPriorities());

  const onAddChoice = (e: any) => {
    const newQ = question.copy();
    const choices = newQ.getChoices();
    choices.push({ text: strings.editQuestion.defaultChoice.replace("{Number}", choices.length + 1) });
    newQ.setChoices(choices);
    setQuestion(newQ);

    const priMap = Object.assign([], priorityMap);
    priMap.push(0);
    setPriorityMap(priMap);
  };

  const onChoiceChanged = (e: any) => {
    const newQ = question.copy();
    const idx = e.target.name.split(".")[1];
    newQ.getChoices()[idx].text = e.target.value;
    setQuestion(newQ);
  };

  const onFormatChoiceChanged = (e: any) => {
    const newQ = question.copy();
    const idx = e.target.name.split(".")[1];
    newQ.getChoices()[idx].text = e.target.value.trim();
    setQuestion(newQ);
  };

  const onPriorityChanged = (e: any) => {
    const idx = e.target.name.split(".")[1];
    const priMap = Object.assign([], priorityMap);
    priMap[idx] = e.target.value;
    setPriorityMap(priMap);
  };

  const onFormChanged = (e: any) => {
    const form = e.target.name.split(".")[1];
    const oldForm = question.getForm();
    const newQ = question.copy();

    if (e.target.checked) {
      if (form === "waitlist") {
        newQ.setForm((oldForm === "both") || (oldForm === "deposit") ? "both" : "waitlist");
      }
      else if (form === "deposit") {
        newQ.setForm((oldForm === "both") || (oldForm === "waitlist") ? "both" : "deposit");
      }
    }
    else if (form === "waitlist") {
      newQ.setForm((oldForm === "both") || (oldForm === "deposit") ? "deposit" : undefined);
    }
    else if (form === "deposit") {
      newQ.setForm((oldForm === "both") || (oldForm === "waitlist") ? "waitlist" : undefined);
    }

    setQuestion(newQ);
  };

  const onProviderChanged = (e: any) => {
    const newQ = question.copy();
    newQ.setProviderQuestion(e.target.checked);
    setQuestion(newQ);
  };

  const onRequiredChanged = (e: any) => {
    const form = e.target.name.split(".")[1];
    const newQ = question.copy();

    if (form === "waitlist") {
      newQ.setRequired(e.target.checked);
    }
    else {
      newQ.setDepositRequired(e.target.checked);
    }
    setQuestion(newQ);
  };

  const onQuestionChanged = (e: any) => {
    const newQ = question.copy();
    newQ.setQuestion(e.target.value);
    setQuestion(newQ);
  };

  const onFormatQuestionChanged = (e: any) => {
    const newQ = question.copy();
    newQ.setQuestion(e.target.value.trim());
    setQuestion(newQ);    
  };

  const onHintChanged = (e: any) => {
    const newQ = question.copy();
    newQ.setHint(e.target.value);
    setQuestion(newQ);
  };

  const onInternalChanged = (e: any) => {
    const newQ = question.copy();
    newQ.setInternal(e.target.checked);
    setQuestion(newQ);
  };

  const onQuestionTypeChanged = (e: any) => {
    const newQ = question.copy();
    let type: "text" | "date" | "referral" | "html" = "text";
    switch (e.target.value) {
      case "date":
        newQ.setChoices(undefined);
        setPriorityMap([]);
        type = "date";
        break;
      case "referral":
        newQ.setChoices([{ text: "Yes" }, { text: "No" }]);
        setPriorityMap([0, 0]);
        type = "referral";
        break;
      case "html":
        newQ.setChoices(undefined);
        newQ.setRequired(false);
        newQ.setDepositRequired(false);
        setPriorityMap([]);
        type = "html";
        break;
      case "freeform":
        newQ.setChoices(undefined);
        setPriorityMap([]);
        break;
      case "yesno":
        newQ.setChoices([{ text: "Yes" }, { text: "No" }]);
        setPriorityMap([0, 0]);
        break;
      case "multiple":
        newQ.setChoices([{ text: strings.editQuestion.defaultChoice.replace("{Number}", 1) }]);
        setPriorityMap([0]);
        break;
    }
    newQ.setType(type);
    setQuestion(newQ);
  };

  const onDone = () => {
    // Set the form for the question if not set
    if (!question.getForm() && (props.form !== "both")) {
      question.setForm(props.form);
    }

    // Map the priorities back - first we need to create a new mapping for choices so we can sort properly
    const rankedPriorities = getQuestionPrioirtyRank(props.allQuestions);
    const choices = (question.getChoices() || []).map((c, idx) => ({
      index: idx,
      priority: priorityMap[idx],
    }));
    choices.sort((a, b) => (a.priority - b.priority));

    choices.forEach(c => {
      const choice = question.getChoices()[c.index];
      if (c.priority === 0) {
        choice.priority = 10;
      }
      else if (c.priority > rankedPriorities.length) {
          // OK, tack this on as midway between the last value and 10, or 100 if no other values
        const last = Math.floor(10 + ((rankedPriorities.length ? rankedPriorities[rankedPriorities.length - 1] : 190) - 10) / 2);
        rankedPriorities.push(last);
        choice.priority = last;
      }
      else {
        choice.priority = rankedPriorities[c.priority - 1];
      }
    });

    // Now callback with this question
    props.onOK(question);
  };

  const renderChoices = () => {
    if (!question.getChoices()) {
      return null;
    }

    return (
      <>
        <Typography className={classes.caption} variant="subtitle2">
          {strings.editQuestion.labelRelativePriorityCaption}
        </Typography>
        <TableContainer>
          <Table stickyHeader aria-label="table" size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.header}>{strings.editQuestion.colPriority}</TableCell>
                <TableCell className={classes.header}>{strings.editQuestion.colChoice}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {question.getChoices().map((c, idx) => (
                <TableRow
                  role="checkbox"
                  tabIndex={-1}
                  key={`{row.${idx}`}
                >
                  <TableCell key={`priority.${idx}`}>
                    <Select
                      labelId="type"
                      id="type"
                      name={`prioirty.${idx}`}
                      variant="outlined"
                      fullWidth
                      value={priorityMap[idx]}
                      onChange={onPriorityChanged}
                    >
                      <MenuItem key="pri1" value={1}>1</MenuItem>
                      <MenuItem key="pri2" value={2}>2</MenuItem>
                      <MenuItem key="pri3" value={3}>3</MenuItem>
                      <MenuItem key="pri4" value={4}>4</MenuItem>
                      <MenuItem key="pri0" value={0}>{strings.editQuestion.priorityDefault}</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell>
                    <LimitTextField
                      id={`choice.${idx}`}
                      name={`choice.${idx}`}
                      key={`choice.${idx}`}
                      variant="outlined"
                      fullWidth
                      size="small"
                      limit={500}
                      showCount={false}
                      value={c.text}
                      onChange={onChoiceChanged}
                      onBlur={onFormatChoiceChanged}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item xs={12}>
          <Button fullWidth className={classes.button} variant="contained" color="secondary" onClick={onAddChoice}>
            {strings.editQuestion.buttonAddChoice}
          </Button>
        </Grid>
      </>
    );
  };

  const renderProviderQuestion = () => {
    if (!props.showProviderOption) {
      return null;
    }

    return (
      <>
        <Divider variant="fullWidth" className={classes.divider} />
        <Typography className={classes.caption} variant="subtitle2">
          {strings.editQuestion.providerQuestion}
        </Typography>
        <FormControlLabel
          control={(
            <Checkbox
              name="provider"
              key="provider"
              checked={question.getProviderQuestion()}
              onChange={onProviderChanged}
              color="primary"
            />
          )}
          label={strings.editQuestion.labelProvider}
        />
      </>
    );
  };

  const renderRequired = () => {
    if ((props.form === "deposit") || (props.form === "waitlist")) {
      if (question.getType() === "html") {
        return null;
      }

      // Just show a required checkbox (we know it's for just one form)
      return (
        <FormControlLabel
          control={(
            <Checkbox
              name={`required.${props.form}`}
              key={`required.${props.form}`}
              checked={(props.form === "waitlist") ? question.getRequired() : question.getDepositRequired()}
              onChange={onRequiredChanged}
              color="primary"
            />
          )}
          className={classes.subCheckbox}
          label={strings.editQuestion.labelRequired}
        />
      );
    }

    return (
      <>
        <Typography className={classes.caption} variant="subtitle2">
          {strings.editQuestion.formDetails}
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <FormControlLabel
              control={(
                <Checkbox
                  name="form.waitlist"
                  key="form.waitlist"
                  checked={(question.getForm() === "waitlist") || (question.getForm() === "both")}
                  onChange={onFormChanged}
                  color="primary"
                />
              )}
              className={classes.formControl}
              label={strings.editQuestion.formWaitlist}
            />
          </Grid>
          {(question.getType() !== "html") && (
            <Grid item xs={12}>
              <FormControlLabel
                control={(
                  <Checkbox
                    name="required.waitlist"
                    key="required.waitlist"
                    checked={question.getRequired()}
                    disabled={!question.getForm() || (question.getForm() === "deposit")}
                    onChange={onRequiredChanged}
                    color="primary"
                  />
                )}
                className={classes.subCheckbox}
                label={strings.editQuestion.labelRequired}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControlLabel
              control={(
                <Checkbox
                  name="form.deposit"
                  key="form.deposit"
                  checked={(question.getForm() === "deposit") || (question.getForm() === "both")}
                  onChange={onFormChanged}
                  color="primary"
                />
              )}
              className={classes.formControl}
              label={strings.editQuestion.formDeposit}
            />
          </Grid>
          {(question.getType() !== "html") && (
            <Grid item xs={12}>
              <FormControlLabel
                control={(
                  <Checkbox
                    name="required.deposit"
                    key="required.deposit"
                    checked={question.getDepositRequired()}
                    disabled={!question.getForm() || (question.getForm() === "waitlist")}
                    onChange={onRequiredChanged}
                    color="primary"
                  />
                )}
                className={classes.subCheckbox}
                label={strings.editQuestion.labelRequired}
              />
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  const renderInternal = () => {
    if (question.getType() === "html") {
      return null;
    }

    // Show an internal box
    return (
      <FormControlLabel
        control={(
          <Checkbox
            name="internal"
            key="internal"
            checked={question.getInternal()}
            onChange={onInternalChanged}
            color="primary"
          />
        )}
        label={strings.editQuestion.labelInternal}
      />
    );
  };

  return (
    <Dialog maxWidth="sm" fullWidth onClose={props.onClose} aria-labelledby="simple-dialog-title" open={props.open}>
      <DialogTitle disableTypography className={classes.root} id="simple-dialog-title" style={{ paddingBottom: 0 }}>
        <Typography variant="h2">
          {props.adding ? strings.editQuestion.titleAdd : strings.editQuestion.title}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={props.onClose}>
          <FontAwesomeIcon icon={faXmark} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="body1">{strings.editQuestion[question.getType() === "html" ? "labelUrl" : "labelQuestion"]}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">{strings.editQuestion.labelType}</Typography>
          </Grid>
          <Grid item xs={8}>
            <LimitTextField
              id="question"
              name="question"
              variant="outlined"
              fullWidth
              value={question.getQuestion()}
              limit={1000}
              onChange={onQuestionChanged}
              onBlur={onFormatQuestionChanged}
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              labelId="type"
              id="type"
              name="type"
              variant="outlined"
              key="type"
              fullWidth
              value={getQuestionType(question)}
              onChange={onQuestionTypeChanged}
            >
              {types.map(t => <MenuItem key={t} value={t}>{strings.editQuestion.types[t]}</MenuItem>)}
            </Select>
          </Grid>
          {(question.getType() === "referral") && (
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.tip}>{strings.editQuestion.tipReferral}</Typography>
            </Grid>
          )}
          {(question.getType() !== "html") && (
            <>
              <Grid item xs={12}>
                <Typography variant="body1">{strings.editQuestion.labelHint}</Typography>
              </Grid>
              <Grid item xs={12}>
                <LimitTextField
                  id="hint"
                  name="hint"
                  variant="outlined"
                  fullWidth
                  value={question.getHint()}
                  limit={1000}
                  onChange={onHintChanged}
                />
              </Grid>
            </>
          )}
        </Grid>
        {renderChoices()}
        {renderProviderQuestion()}
        <Divider variant="fullWidth" className={classes.divider} />
        {renderRequired()}
        {renderInternal()}
        <Button
          fullWidth
          className={classes.button}
          variant="contained"
          color="primary"
          disabled={(props.form === "both") && !question.getForm()}
          onClick={onDone}
        >
          {props.adding ? strings.editQuestion.buttonAdd : strings.editQuestion.buttonSave}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default EditQuestion;
